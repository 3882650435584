.App {
  text-align: left;
}

.App-header {
  margin: 10px;
  color: #FFFFFF;
  font-family: 'PT Sans', sans-serif;
}

.App-form {
  margin: 20px;
}

.App-fullscreen {
  background-color: #fbfbfb;
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.App-unsigned-card {
  width: 500px;
  margin-top: 100px !important;
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  box-shadow: 1px 2px 1px #9E9E9E;
}

.App-unsigned-title {
  color: #FFFFFF !important;
  font-size: 22px !important;
  font-family: 'PT Sans', sans-serif;
}

.App-profile-item {
  border-width: 0 !important;
}

div.ant-upload{
  width: 100%;
}

.App-SubHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #232F3E;
  padding: 0 20px 0 20px;
}

.App-SubHeader ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: transparent;
}

.App-SubHeader ul li {
  float: left;
}

.App-SubHeader .ant-menu-item {
  border-bottom: 0 !important;
  margin: 0 !important;
  top: 0 !important;
}

.App-SubHeader .ant-menu-submenu {
  border-bottom: 0 !important;
  margin: 0 !important;
  top: 0 !important;
}

.App-SubHeader .ant-menu-horizontal {
  line-height: 18px;
  border-bottom: 0;
}

.App-SubHeader ul li a {
  display: block;
  font-size: 14px;
  color: white;
  text-align: center;
  padding: 12px 16px;
  text-decoration: none;
}

.App-SubHeader ul li a:hover {
  background-color: #1e2632;
}

.App-Table td, .App-Table p {
  color: #5d5d5d,
}
.App-Table a {
  text-decoration: none !important;
  color: #4A4A4A;
}

.App-Table a:hover, .App-Link a:hover {
  color: #252525 !important;
}

.App-Link {
  text-decoration: none !important;
  color: #4A4A4A !important;
}

.App-Link:hover {
  color: #252525 !important;
}

.App-SubMenu {
  background-color: #232F3E !important;
  :hover {
    background-color: #1e2632 !important;
  }
}

.App-SubMenu li {
  color: white !important;
}

.App-winner-cell {
  background: #cbdfb8 !important;
  text-align: center;
  cursor: pointer;
}

.App-winner-cell:hover {
  background: #bed7a6 !important;
}

.App-loser-cell {
  background: #f25050 !important;
  text-align: center;
  cursor: pointer;
}

.App-loser-cell:hover {
  background: #f03838 !important;
  cursor: pointer;
}

.App-stat-popover div[class="ant-popover-inner-content"] {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.App-Thin-Collapse .ant-collapse-header  {
  padding: 0 !important;
}

.App-Thin-Collapse .ant-collapse-content-box {
  padding: 0 !important;
  margin: 0 !important;
}

.App-Thin-Collapse:hover {
  filter: brightness(0.98);
}

.App-Rate-Small .ant-rate-star {
  font-size: 14px;
  margin-right: 3px !important;
}

.App-Rate-Medium .ant-rate-star {
  font-size: 16px;
  margin-right: 3px !important;
}

.App-Price {
  font-size: 21px;
  font-weight: bold;
  position: relative;
}

.App-Select-Readonly {
  .ant-select-selection-search {
    display: none;
  }
  .anticon-close {
    display: none;
  }
}